<template>
    <div>
        <h1>COVID-19</h1>
    </div>
</template>

<style lang="scss" scoped>
  div {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>